import { TRoute, TRouteName } from './t-route.dto';

export const WorkflowRoutes: TRoute[] = [
  { value: '/configuracion/usuarios', name: 'default' },
  { value: '/login', name: 'login' },
  { value: '/configuracion/usuarios/editar', name: 'editUser' },
  { value: '/configuracion/usuarios/editarProveedor', name: 'editUserSupplier' },
  { value: '/configuracion/usuarios', name: 'listUser' },
  { value: '/configuracion/usuarios/crear', name: 'createUser' },
  { value: '/solicitudes/lista', name: 'listRequest' },
  { value: '/solicitudes/crear', name: 'createRequest' },
  { value: '/configuracion/auditoria', name: 'listAudit' },
  { value: '/solicitudes/detalle', name: 'detailRequest' },
  { value: '/solicitudes/editar', name: 'editRequest' },
  { value: '/pedidos', name: 'listOrder' },
  { value: '/pedidos/detalle', name: 'detailOrder' },
  { value: '/solicitudes/calificar', name: 'ratingRequest' },
  { value: '/configuracion/liberacion', name: 'listLiberation' },
  { value: '/configuracion/liberacion/crear', name: 'createLiberation' },
  { value: '/configuracion/liberacion/editar', name: 'editLiberation' },
  { value: '/solicitudes/calificarEditar', name: 'editRating' },
];

export function getRouteByName(name: TRouteName): TRoute | null {
  const find = WorkflowRoutes.find((route) => {
    return route.name == name;
  });
  return find ? find : null;
}

export function getRouteByValue(value: string): TRoute | null {
  const find = WorkflowRoutes.find((route) => {
    return route.value == value;
  });
  return find ? find : null;
}
